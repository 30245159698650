<template>
  <div class="flix-tableau flix-flex">
    <div
      class="flix-flex flix-flex-center flix-gap-5 flix-tableau-titel"
      style="margin-bottom: 0"
    >
      <a
        href="#"
        class="flix-html-a"
        @click.prevent="setWeekday(index)"
        v-for="(weekday, index) in $store.state.business.unsaved.include
          .weekdays"
        :key="index"
      >
        <icon id="wrench" class="flix-desktop" />
        <span class="flix-desktop">{{ $t("dates.weekdays")[index] }}</span>
        <span class="flix-mobile"
          >{{ $t("dates.weekdays")[index][0]
          }}{{ $t("dates.weekdays")[index][1] }}</span
        >
      </a>
    </div>
    <div class="flix-tableau-container flix-tableau flix-flex flix-gap-10">
      <preview
        class="flix-flex flix-gap-5 flix-tableau-preview"
        :style="'padding-top:' + marginTop[index] + 'px'"
        v-for="(weekday, index) in $store.state.business.unsaved.include
          .weekdays"
        :key="index"
        :weekday="index"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    preview() {
      return import("./preview");
    }
  },
  props: {},
  data() {
    return {
      marginTop: {}
    };
  },
  computed: {},
  mounted() {
    this.setMarginTop();
  },
  methods: {
    setMarginTop() {
      var timestamps = {};
      var weekdays = this.$store.state.business.unsaved.include.weekdays;
      var check = false;
      var weekday = false;
      Object.keys(weekdays).forEach(function(k) {
        if (typeof weekdays[k][0] !== "undefined" && weekdays[k][0]) {
          weekday = k;
          var i = weekdays[k][0].split(":");
          timestamps[k] = new Date();
          timestamps[k].setHours(i[0]);
          timestamps[k].setMinutes(i[1]);
          timestamps[k].setSeconds(0);
          timestamps[k].setMilliseconds(0);

          if (!check || check.getTime() > timestamps[k].getTime()) {
            check = timestamps[k];
          }
        }
      });
      this.$store.commit("business/setWeekday", weekday);
      var r = {};
      Object.keys(timestamps).forEach(function(k) {
        if (check.getTime() === timestamps[k].getTime()) {
          r[k] = 0;
          return true;
        }

        var calc = timestamps[k].getTime() - check.getTime();
        calc = calc / 1000;
        calc = calc / 60;
        calc = calc / 15;

        calc = calc * 60 + calc * 8;
        r[k] = calc;
      });
      this.marginTop = r;
    },
    setWeekday(index) {
      this.$store.commit("business/setWeekday", index);
      this.$store.commit("tableau/setView", "day");
    }
  }
};
</script>
<style lang="sass" scoped></style>
